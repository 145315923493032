import React from "react";
import PropTypes from "prop-types";
import tw, { styled } from "twin.macro";

const Content = styled.div`
  ${tw`w-full pb-8 px-4 md:px-12 grid lg:grid-rows-2 grid-flow-row gap-y-16`};
`;

const Sections = ({ children }) => {
  return <Content>{children}</Content>;
};

Sections.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sections;
