import React from "react";
import PropTypes from "prop-types";
import tw, { styled } from "twin.macro";
import SectionItem from "./sectionitem.js";

const Content = styled.div`
  ${tw``};
`;

const Header = styled.div`
  ${tw`text-2xl font-black text-purple-dark`}
`;

const List = styled.div`
  ${tw`grid lg:grid-cols-2 gap-8 pt-4`}
`;

const Section = ({ title, items }) => {
    return (
        <Content>
            <Header>{title.text}</Header>

            <List>
                {items.map((item, index) => (
                    <SectionItem key={index} {...item} />
                ))}
            </List>
        </Content>
    );
};

Section.propTypes = {
    title: PropTypes.object,
    items: PropTypes.array.isRequired,
};

export default Section;
