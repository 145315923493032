import React from "react";
import PropTypes from "prop-types";
import tw, { styled } from "twin.macro";

const Content = styled.div`
  ${tw`block`};
`;

const PrimaryText = styled.div`
  ${tw`font-bold text-purple-dark`}
`;

const PrimaryLink = styled.a`
  ${tw`font-bold text-purple-dark flex items-center no-underline hover:underline`}

  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;

const SecondaryText = styled.div`
  ${tw`text-gray-600`}
  p {
    ${tw`m-0`}
  }
  a {
    ${tw`text-purple-dark no-underline opacity-75 hover:opacity-100`}
  }
`;

const SectionItem = ({ primary_text, secondary_text, external_link }) => {
    return (
        <Content>
            {external_link.url ? <PrimaryLink href={external_link.url} target="_blank">{primary_text.text} <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0-6.75-6.75M19.5 12l-6.75 6.75" /></svg></PrimaryLink> : <PrimaryText>{primary_text.text}</PrimaryText>}
            <SecondaryText dangerouslySetInnerHTML={{
                __html: secondary_text.html,
            }} />
        </Content>
    );
};

SectionItem.propTypes = {
    primary_text: PropTypes.object,
    secondary_text: PropTypes.object,
    external_link: PropTypes.object,
};

export default SectionItem;
