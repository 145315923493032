import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";

import Biography from "../components/biography";
import Sections from "../components/sections";
import ProjectList from "../components/projectlist";
import Section from "../components/section";
import tw, { styled } from "twin.macro";

import DocumentIcon from '../../assets/icons/document.svg';
import EmailIcon from '../../assets/icons/email.svg';
import GithubIcon from '../../assets/icons/github.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import PaperClipIcon from '../../assets/icons/paper-clip.svg';

const H1 = styled.div`
  ${tw`text-4xl font-bold`};
`;

const ProfileImage = styled.img`
  ${tw`mb-4`};
`;

const H2 = styled.div`
  ${tw`text-2xl font-light`};
`;

const H3 = styled.div`
  ${tw`mt-6 text-xl font-black`};
`;

const LinksSection = styled.div`
  ${tw`flex space-x-8 w-1/2 mb-10`};
`;

const CtaLink = styled.a`
  ${tw`text-base text-purple-dark flex items-center no-underline opacity-75 hover:opacity-100`}

  svg {
    ${tw`mr-2 w-4 h-4`}
  }
`;

const Text = styled.div`
  ${tw`w-3/4`}

  p {
    ${tw`pb-6`}

    strong {
      ${tw`font-bold`}
    }

    a {
      ${tw`font-bold no-underline hover:text-purple-light`}
    }
  }
`;

const SVGMapper = {
  'document': <DocumentIcon/>,
  'email': <EmailIcon/>,
  'github': <GithubIcon/>,
  'linkedin': <LinkedinIcon/>,
  'paperclip': <PaperClipIcon/>,
};

const Index = ({ data: { allPrismicHomepage } }) => {
  const links = allPrismicHomepage.edges[0].node.data.body.find((section) => section.slice_type === `ctas`)

  return (
    <Layout>
      <SEO />
      <Biography notice={allPrismicHomepage.nodes[0].data.notice.text}>

        <ProfileImage src={allPrismicHomepage.nodes[0].data.profile.url} />

        <H1>{allPrismicHomepage.nodes[0].data.header.text}</H1>
        <H2>{allPrismicHomepage.nodes[0].data.subheader.text}</H2>

        <H3>Briefly about me</H3>
        <Text
          dangerouslySetInnerHTML={{
            __html: allPrismicHomepage.nodes[0].data.text.html,
          }}
        />

        <LinksSection>
          {links.items.map((section, index) => (
            <CtaLink href={section.button_link.url} target="_blank">
              {SVGMapper[section.button_icon]}

              {section.button_text.text}
            </CtaLink>
          ))}
        </LinksSection>
      </Biography>

      <Sections>
        {allPrismicHomepage.edges[0].node.data.body.map((section, index) => {
          switch (section.slice_type) {
            case `opensource_projects`:
              return <ProjectList
                title={section.slice_type}
                key={index}
                items={section.items}
              />
            case `section`:
              return <Section
                title={section.primary.section_title}
                key={index}
                items={section.items}
              />
          }

          return null
        })}
      </Sections>
    </Layout>
  )
};

export default Index;
export const pageQuery = graphql`
  {
    allPrismicHomepage {
      nodes {
        data {
          notice {
            html
          }
          header {
            text
          }
          subheader {
            text
          }
          text {
            html
          }
          profile {
            url(imgixParams: {h: 160, w: 160, crop: "faces", fit: "facearea", mask: "ellipse", facepad: 3.0})
          }
        }
      }
      edges {
        node {
          id
          data {
            body {
              __typename
              ... on PrismicHomepageBodyCtas {
                id
                slice_type
                slice_label
                items {
                  button_icon
                  button_text {
                    text
                  }
                  button_link {
                    url
                  }
                }
              }
              ... on PrismicHomepageBodySection {
                id
                slice_type
                slice_label
                primary {
                  section_title {
                    text
                  }
                }
                items {
                  primary_text {
                    text
                  }
                  secondary_text {
                    html
                  }
                  external_link {
                    url
                  }
                }
              }
              ... on PrismicHomepageBodyOpensourceProjects {
                id
                items {
                  website {
                    url
                  }
                  name {
                    text
                  }
                  logo {
                    url
                  }
                  language {
                    text
                  }
                  description {
                    text
                  }
                }
                slice_label
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;
